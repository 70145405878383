import React, { useEffect, useState, useCallback } from "react";
import { App } from "@bluewren/core-module";
import * as flightModules from "./imports";
import axios from "axios";
const config = require("./config/config.json");
const moduleDictionary = require("./config/moduleDictionary.json");

const useConfigFromServer = ({ configTimeStamp, configFromStorage }) => {
    const [configState, setConfigState] = useState("INITIAL");
    const [configToUse, setConfigToUse] = useState(config);

    const isUAT = process?.env?.REACT_APP_MYFLIGHT_ROOT_DOMAIN == ".uat-app.co.uk";

    useEffect(() => {
        if (isUAT) {
            fetchConfig();
        } else {
            setConfigState("READY");
        }
    }, []);

    const fetchConfig = useCallback(async (e) => {
        setConfigState("FETCHING");
        try {
            const response = await axios({
                url: `${process.env.REACT_APP_API}/flight-config`,
                method: "get",
                params: { timestamp: configTimeStamp },
            });
            if (response.data !== undefined) {
                setConfigState("READY");

                if (response?.data?.config) {
                    localStorage.setItem("config", JSON.stringify(response.data.config));
                    localStorage.setItem("config-timestamp", JSON.stringify(response.data.timestamp));
                    setConfigToUse(response?.data?.config);
                } else {
                    if (response?.data?.timestamp) {
                        localStorage.setItem("config-timestamp", JSON.stringify(response.data.timestamp));
                        configFromStorage && setConfigToUse(configFromStorage);
                    }
                }
            }
        } catch (e) {
            setConfigState("READY"); // this forces the fallback to the coinfg that was bundled with the application if an error occurs when attempting to fetch it
            console.log(e);
        }
    }, []);

    return {
        configToUse,
        configState,
    };
};

const AppEntry = ({ ...props }) => {
    const configTimeStamp = JSON.parse(localStorage.getItem("config-timestamp"));
    const configFromStorage = JSON.parse(localStorage.getItem("config"));
    const { configToUse, configState } = useConfigFromServer({ configTimeStamp, configFromStorage });

    return configState == "READY" && configToUse ? (
        <App {...{ config: configToUse, moduleDictionary, flightModules, env: process.env }} {...props} />
    ) : null;
};

export default AppEntry;
