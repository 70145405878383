
            import * as theme from "@bluewren/core-module";
import * as auditnotes from "@bluewren/auditnotes-module";
import * as auth from "@bluewren/core-module";
import * as people from "@bluewren/clients-module";
import * as groups from "@bluewren/clients-module";
import * as files from "@bluewren/files-module";
import * as notes from "@bluewren/notes-module";
import * as notifications from "@bluewren/notifications-module";
import * as tasks from "@bluewren/tasks-module";
import * as users from "@bluewren/users-module";
import * as roles from "@bluewren/roles-module";
import * as documenttemplates from "@bluewren/documenttemplates-module";
import * as core from "@bluewren/core-module";
            export {theme,auditnotes,auth,people,groups,files,notes,notifications,tasks,users,roles,documenttemplates,core}
    